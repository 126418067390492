import React from 'react';
import NodeNew from 'components/admin/node/new';
import endpoints from 'components/bdtx/migrate-etep-data/endpoints';
import { CHUONG_TRINH_01 } from 'components/bdtx/configs';
import schema from './schema';
import { Alert } from 'antd';
import { getTargetUser, userGradeToText } from 'configs/constants/user';
import { getSchoolYearOfCurrentUser } from 'components/common/school-year-config/utils';

const FORM_ID = 'migrate-etep-data';

const Migrate = ({ migrationData, searchFormId, closeDialog }) => {
  const { iid, name, migration } = migrationData;
  const { target_user, cap } = migration;

  return (
    <>
      <Alert
        message="Bạn đang thực hiện kết chuyển dữ liệu ETEP sang bên BDTX"
        description={
          <>
            Bạn đang thực hiện kết chuyển dữ liệu của Đợt tập huấn:{' '}
            <b>{name}</b>, cấp: <b>{userGradeToText(cap)}</b>, đối tượng:{' '}
            <b>{getTargetUser(target_user)}</b>
          </>
        }
        type="warning"
        className="m-b-15"
        showIcon
      />

      <NodeNew
        alternativeApi={endpoints.migrate}
        schema={schema}
        searchFormId={searchFormId}
        formid={FORM_ID}
        hiddenFields={{
          training_plan_iid: iid,
          target_user,
          cap,
          mode: 'single',
        }}
        submitButton={() => (
          <div className="text-center m-t-15">
            <button
              className="btn btn-secondary m-r-10"
              onClick={closeDialog}
              type="button"
            >
              Không kết chuyển
            </button>

            <button className="btn btn-primary">Kết chuyển dữ liệu</button>
          </div>
        )}
        node={{
          school_year: getSchoolYearOfCurrentUser(),
          chuong_trinh: CHUONG_TRINH_01,
        }}
        mode="edit"
      />
    </>
  );
};

export default Migrate;
