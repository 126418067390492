import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './stylesheet.scss';
import { Collapse } from 'antd';

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: props && props.item && props.item.expand,
    };
  }

  handleExpandItem = () => {
    this.setState({ expand: !this.state.expand });
  };

  render() {
    const { item } = this.props;

    const question = <span className="question__content">{item.question}</span>;
    const { expand } = this.state;
    return (
      <div className="m-b-25">
        <div className="faq-container">
          <Collapse defaultActiveKey={item.iid}>
            <Panel header={question} key={item.iid}>
              <div className="answer-container" onClick={this.handleExpandItem}>
                <div className="answer">
                  <span
                    className={`answer__content ${
                      expand ? 'content-expand' : 'content-collapse'
                    }`}
                    style={expand ? { whiteSpace: 'pre-wrap' } : null}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  item: PropTypes.instanceOf(Object),
};

Index.defaultProps = {
  item: {},
};
export default connect()(Index);
