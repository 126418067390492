import { call, put, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import actions from 'actions/node/creators';
import callApiMultipleTimes from 'sagas/node/common/callApiMultipleTimes';
import lodashGet from 'lodash.get';

//= =======================================
function* getDataRequestByParams(action, params, showProgress = true) {
  const { formid, alternativeApi } = action;
  const callApiMultipleTimesSettings = lodashGet(
    action,
    'options.callApiMultipleTimesSettings',
  );
  const method = lodashGet(action, 'options.method');

  const url = alternativeApi || apiUrls[formid] || '';

  const req = ['post', 'POST'].includes(method)
    ? Requester.post
    : Requester.get;

  let data;

  if (callApiMultipleTimesSettings) {
    data = yield call(
      callApiMultipleTimes,
      callApiMultipleTimesSettings,
      req,
      url,
      params,
    );
  } else {
    data = yield call(req, url, params, {}, showProgress);
  }

  return data;
}

const getDefaultTotalBySearchValues = (params) => {
  const itemsPerPage = lodashGet(params, 'items_per_page');
  const page = lodashGet(params, 'page');

  return page * itemsPerPage + 1;
};

function* getTotal(formId, resultId, searchResultKey, params, action) {
  const getTotalRecordsKey = lodashGet(params, 'get_total_records_key');
  let total = 0;

  if (getTotalRecordsKey) {
    const data = yield call(getDataRequestByParams, action, params, false);
    total = lodashGet(data, 'objects.total') || lodashGet(data, 'result', 0);
    if (!Number.isInteger(total)) {
      total = 0;
    }
  }

  yield put(
    actions.updateTotalSearchResults(
      total || getDefaultTotalBySearchValues(params),
      searchResultKey || formId,
      resultId,
      !!total,
    ),
  );
}

function* fetchNodes(action) {
  const formId = action.formid;
  const params = Object.assign({}, action.values || {});
  const options = action.options || {};
  const alternativeApi = action.alternativeApi;
  const searchResultKey = action.searchResultKey || formId;
  const showMessageFromServer = options.showMessageFromServer;

  const resultId = new Date().getTime();

  // console.log({options});
  // put a flag indicating that we're submitting...
  if (formId) {
    yield put(startSubmit(formId));
  }

  const _sand_get_total = lodashGet(params, '_sand_get_total');
  if (_sand_get_total) {
    params._sand_get_total = 0;
  }

  const data = yield call(getDataRequestByParams, action, params);

  yield put(
    actions.updateSearchResults(data, searchResultKey, action.values, resultId),
  );

  if (data && data.success) {
    if (data.message && showMessageFromServer) {
      yield put(actions.snackbar('success', data.message));
    }

    // update the store.tree
    if (data.result && data.result.length > 0) {
      yield put(actions.treeUpsertNodes(data.result, 'search'));
    } else if (!data.result) {
      data.result = [];
    }
    if (options && options.onSuccess) {
      options.onSuccess(data.result);
    }
    // yield call(resolve);
  } else if (data && typeof data.success !== 'undefined') {
    // yield call(reject, {location: 'No data for that location'});
    console.log('error: Nothing found');
    if (data.message && showMessageFromServer) {
      yield put(actions.snackbar('error', data.message));
    }

    if (options && options.onFail) {
      options.onFail(data.result);
    }
  } else {
    console.log('network request error ');
    if (options && options.onFail) {
      options.onFail(data.result);
    }
  }

  if (formId) {
    yield put(stopSubmit(formId));
  }

  if (_sand_get_total) {
    const total = lodashGet(data, 'objects.total') || lodashGet(data, 'total');
    const count = lodashGet(data, 'objects.count') || lodashGet(data, 'count');

    if (total || !count) {
      return;
    }

    const getTotalRecordsKey =
      lodashGet(data, 'objects.get_total_records_key') ||
      lodashGet(data, 'get_total_records_key');

    delete params._sand_get_total;
    params._sand_only_get_total = 1;
    params.get_total_records_key = getTotalRecordsKey;

    yield call(getTotal, formId, resultId, searchResultKey, params, action);
  }
}

export default function* fetchNodesSaga() {
  yield takeEvery('FETCH_NODES_REQUEST', fetchNodes);
}

// export default fetchNodesSaga;
