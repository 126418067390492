import lodashGet from 'lodash.get';
import lodashSet from 'lodash.set';

const getTotalByDataResponseData = (data) => {
  return lodashGet(data, 'objects.total') || lodashGet(data, 'total') || 0;
};
const getTotalBySearchValues = (currentData, newData, searchValues) => {
  const newTotal = getTotalByDataResponseData(newData);

  if (newTotal) {
    return newTotal;
  }

  const page = lodashGet(searchValues, 'page', 1);
  const itemsPerPage = lodashGet(searchValues, 'items_per_page', 10);

  const currentTotal = getTotalByDataResponseData(currentData);
  const count =
    lodashGet(newData, 'objects.count') || lodashGet(newData, 'count') || 0;
  const totalBySearchValues = (page - 1) * itemsPerPage + count;

  return currentTotal >= totalBySearchValues
    ? currentTotal
    : totalBySearchValues + (itemsPerPage == count ? 1 : 0);
};

const setDataState = (currentData = {}, newData, searchValues, resultId) => {
  const _sand_get_total = lodashGet(searchValues, '_sand_get_total');
  if (typeof _sand_get_total !== 'undefined' && !_sand_get_total) {
    const total = getTotalBySearchValues(currentData, newData, searchValues);
    if (typeof lodashGet(newData, 'objects.count') != 'undefined') {
      lodashSet(newData, 'objects.total', total);
    }

    if (typeof lodashGet(newData, 'count') != 'undefined') {
      lodashSet(newData, 'total', total);
    }
  }

  const data = Object.assign(currentData, newData);
  const selectedItems =
    newData.selectedItems || currentData.selectedItems || [];
  const checkKey = newData.checkKey || currentData.checkKey || '';
  let result = newData.result || currentData.result;
  if (newData && typeof newData.success !== 'undefined') {
    result = newData.result;
  }
  const selectedList = [];
  selectedItems.forEach((item) => {
    selectedList.push(item[checkKey]);
  });

  if (result && result.length) {
    result.forEach((item) => ({
      ...item,
      selected: selectedList.indexOf(item[checkKey]) !== -1,
    }));
  }

  return {
    ...data,
    resultId: resultId || new Date().getTime(),
    result,
    selectedItems,
    checkKey,
    searchValues,
  };
};

function searchResults(state = {}, action) {
  switch (action.type) {
    case 'SEARCH_RESULTS_FETCHED': {
      const { data, formid, searchValues, resultId } = action;
      const currentData = state[formid] || {};

      if (resultId && lodashGet(currentData, 'resultId') > resultId) {
        return state;
      }

      return {
        ...state,
        [formid]: {
          ...setDataState(currentData, data, searchValues, resultId),
          showTotalItem:
            lodashGet(searchValues, '_sand_get_total') &&
            !getTotalByDataResponseData(data)
              ? false
              : lodashGet(currentData, 'showTotalItem', true),
        },
      };
    }
    case 'TOTAL_SEARCH_RESULTS_FETCHED': {
      const { total, formid, resultId, showTotalItem } = action;
      const currentData = state[formid] || {};

      if (resultId && lodashGet(currentData, 'resultId') != resultId) {
        return state;
      }

      const objects = lodashGet(currentData, 'objects');
      if (typeof objects !== 'undefined') {
        lodashSet(currentData, 'objects.total', total);
      } else {
        lodashSet(currentData, 'total', total);
      }

      lodashSet(currentData, 'showTotalItem', showTotalItem);

      return {
        ...state,
        [formid]: currentData,
      };
    }
    case 'CLEAN_SEARCH_RESULTS': {
      const { formid } = action;
      const newState = { ...state };
      delete newState[formid];

      return newState;
    }
    case 'UPDATE_DATA_OF_ROW': {
      const { formid, dataId, data } = action;
      const newState = { ...state };
      const searchData = newState[formid];
      let result = [];
      let dataUpdated = false;
      if (searchData) {
        const tableData = searchData.result || [];
        tableData.map((row) => {
          if (row.id === dataId) {
            result.push(data);
            dataUpdated = true;
          } else {
            result.push(row);
          }
        });
      }
      if (!dataUpdated) {
        result = [data, ...result];
      }

      return { ...newState, [formid]: { ...searchData, result } };
    }
    case 'DELETE_DATA_OF_ROW': {
      const { formid, id } = action;
      const newState = { ...state };
      const searchData = newState[formid];
      let result = [];
      if (searchData) {
        const dataResult = searchData.result || [];
        dataResult.map((row) => {
          if (row.id != id) {
            result.push(row);
          }
        });
        return { ...newState, [formid]: { ...searchData, result } };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}

export default searchResults;
