import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getAssessmentPhaseOfCurrentUser } from 'components/temis/components/scholastic-config/utils';

const withOutOfDeadlineAssessment = (WrappedComponent) => {
  class OutOfDeadlineAssessment extends Component {
    checkOutOfDeadlineAssessment = () => {
      const { scholastic = '', config = {} } = this.props;
      const scholasticConfigId = get(config, 'id');

      if (scholastic !== scholasticConfigId) {
        return true;
      }

      if (get(config, 'lock_assessment')) {
        return true;
      }

      return false;
    };

    render() {
      const { config = {} } = this.props;
      const isOutOfDeadlineAssessment = this.checkOutOfDeadlineAssessment();

      return (
        <WrappedComponent
          {...this.props}
          isOutOfDeadlineAssessment={isOutOfDeadlineAssessment}
          lockAssessment={get(config, 'lock_assessment')}
          lockAssessmentInfo={get(config, 'lock_assessment_info')}
        />
      );
    }
  }

  const mapStateToProps = (state) => {
    const scholasticConfig = get(state, 'scholasticConfig.config', {});
    return {
      scholastic: getAssessmentPhaseOfCurrentUser(),
      config: scholasticConfig,
    };
  };

  return connect(mapStateToProps)(OutOfDeadlineAssessment);
};

export default withOutOfDeadlineAssessment;
