import React from 'react';
import Checkbox from 'antd/lib/checkbox';
import RaisedButton from 'components/common/mui/RaisedButton';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { getAssessmentPhaseOfCurrentUser } from 'components/temis/components/scholastic-config/utils';
import useDisplayErrorDialogWhenDeleteFailed from 'components/admin/user/account/delete-users/useDisplayErrorDialogWhenDeleteFailed';
import withGlobalDialogs from 'components/common/modal/withGlobalDialogs';
import { submit } from 'redux-form';

const ContentConfirmDelete = ({
  setSchoolYearsSelected,
  scholastic,
  schoolYears,
}) => {
  const [value, onChange] = React.useState([scholastic]);
  React.useEffect(
    () => {
      setSchoolYearsSelected(value);
    },
    [value, setSchoolYearsSelected],
  );

  return (
    <Checkbox.Group
      className="m-b-20 m-l-30"
      options={[
        {
          label: 'Tài khoản trên Tập Huấn',
          value: 'in_taphuan',
        },
      ].concat(
        Array.isArray(schoolYears)
          ? schoolYears.map(({ name, id }) => ({
              label: name,
              value: id,
              disabled: id === scholastic,
            }))
          : [],
      )}
      value={value}
      onChange={onChange}
    />
  );
};

const DeleteAccountsButton = ({
  selectedRowKeys,
  onSelectRowKeysChange,
  searchFormId,
  schoolYears,
  scholastic,
  iconButton = false,
  itemId,
  canSelectAssessmentPhase,
  openDialog,
  closeDialog,
  dispatch,
}) => {
  const [schoolYearsSelected, setSchoolYearsSelected] = React.useState([
    scholastic,
  ]);

  const errorDialogKey = 'button_delete_accounts_errors';

  const executeOnFailure = useDisplayErrorDialogWhenDeleteFailed({
    openDialog,
    closeDialog,
    dialogKey: errorDialogKey,
    onCloseDialog: () => {
      dispatch(submit(searchFormId));
    },
    selectedAssessmentPhasesOptions: schoolYearsSelected,
  });

  return (
    <ApiRequestBtnWithConfirmDialog
      renderComponent={({ onClick }) => {
        if (iconButton) {
          return <Icon icon="delete" className="action" onClick={onClick} />;
        }
        return (
          <RaisedButton
            primary
            disabled={
              !Array.isArray(selectedRowKeys) || !selectedRowKeys.length
            }
            onClick={onClick}
            icon={<Icon icon="delete" style={{ color: 'white' }} />}
            label={t1('delete_%s_account', [
              Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0,
            ])}
            className="m-t-10 m-r-10"
          />
        );
      }}
      onRequestSuccessful={() => onSelectRowKeysChange([])}
      formidToSubmitOnSuccess={searchFormId}
      url="/user/delete"
      textConfirm={t1('are_you_sure_you_want_to_delete_accounts')}
      contentConfirm={
        canSelectAssessmentPhase ? (
          <div>
            <ContentConfirmDelete
              setSchoolYearsSelected={setSchoolYearsSelected}
              schoolYears={schoolYears}
              scholastic={scholastic}
            />
          </div>
        ) : null
      }
      params={() => ({
        id: itemId,
        ids: selectedRowKeys,
        assessment_phases: schoolYearsSelected,
      })}
      executeOnFailure={executeOnFailure}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    scholastic: getAssessmentPhaseOfCurrentUser(),
    schoolYears: get(state, 'scholasticConfig.schoolYears'),
  };
};
export default connect(mapStateToProps)(
  withGlobalDialogs()(DeleteAccountsButton),
);
