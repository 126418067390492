import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { caphoc, chuong_trinh, targetUser } from 'components/common/elements';
import { organizations } from 'components/admin/organization/schema/elements';
import { userGrades } from 'configs/constants/user';
import { CHUONG_TRINH_03 } from 'components/bdtx/configs';
import subTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, step, xpath, props) => {
  const schoolYear = lodashGet(values, 'school_year');

  return {
    chuong_trinh: chuong_trinh(
      {
        classWrapper: 'col-md-5',
      },
      {
        excludeChuongTrinhs: [CHUONG_TRINH_03],
      },
    ),
    // school_year: listSchoolYear({
    //   floatingLabelText: 'Năm học',
    //   multiple: false,
    //   validate: [required('school_year_cannot_be_empty')],
    //   classWrapper: 'col-md-4',
    // }),
    organizations: organizations({
      formid,
      multiple: false,
      label: t1('user_organization'),
      classWrapper: 'col-md-4',
      populateDefaultValue: true,
      isBdtx: true,
      subTypesDiscarded: [subTypes.TAPHUAN_SUBTYPE_LTTU],
    }),
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-4',
      defaultValue: 'gv',
    }),
    cap: caphoc({
      classWrapper: 'col-md-4',
      type: 'radio',
      defaultValue: userGrades.PRIMARY,
    }),
  };
};

const ui = () => {
  let fields = [
    // 'chuong_trinh',
    // 'school_year',
    'organizations',
    'cap',
    'target_user',
  ];

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
