import React from 'react';
import get from 'lodash.get';

import { t, t1 } from 'translate';
import Perm from 'common/utils/Perm';
import {
  courseLearningTypes,
  freezeMode,
  languages,
  learningMethods,
  leftMenuStates,
  nodeRoles,
} from 'configs/constants';
import layoutContextAction from 'actions/layout-context';
import { getProjectOfCurrentUser } from 'components/common/switch-project/utils';
import { APPLICABLE_PROJECT } from 'configs/constants/project';
import getUser from 'common/auth';
// TODO: Không biết vì sao, nhưng khi xóa import này thì app sẽ bị chết.
import { isBDTXRoute } from 'utils/router';
import {
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_WEB,
} from 'configs/constants/devices';
import { isEnableTemis } from 'components/temis/utils';
import {
  doesUserHaveMission,
  isAmTinh,
  isBo,
  isSoOrPhong,
} from 'components/admin/user/utils';

const defaultLanguage = languages[0];

export const isOnlineCourse = (course) =>
  course && course.learning_type === courseLearningTypes.ONLINE;

export const openLeftMenu = (dispatch) => () =>
  dispatch(layoutContextAction.setStateOfLeftMenu(leftMenuStates.OPENED));

export const closeLeftMenu = (dispatch) => () =>
  dispatch(layoutContextAction.setStateOfLeftMenu(leftMenuStates.CLOSED));

export function displayAvatar(avatar) {
  if (!avatar) return '';
  return avatar.startsWith('http')
    ? avatar
    : `${window.APP_STATIC_CDN}/${avatar}`;
}

export function displayOrganizationTypeLabel(orgTypes, orgType) {
  if (!orgTypes) {
    return t1('you_need_to_config_org_types_on_system_side');
  }

  const orgTypeObject = orgTypes.find(
    (orgTypeItem) => parseInt(orgTypeItem.type) === parseInt(orgType),
  );

  return orgTypeObject && t1(orgTypeObject.name);
}

export function filterLocationTypesBaseOnSupportedLearningMethods(
  supportedLearningMethods,
  getLocationTypes,
) {
  if (supportedLearningMethods && supportedLearningMethods.length > 0) {
    const index = supportedLearningMethods.indexOf(learningMethods.ONLINE);

    if (index !== -1) {
      supportedLearningMethods.splice(index, 1);
    }
  }

  return getLocationTypes.filter(
    (opt) =>
      !supportedLearningMethods ||
      supportedLearningMethods.length === 0 ||
      supportedLearningMethods.includes(opt.value),
  );
}

export function filterRequestTypesBaseOnSupportedRequestTypes(
  supportedRequestTypes,
  requestTypes,
) {
  return requestTypes.filter(
    (opt) =>
      !supportedRequestTypes ||
      supportedRequestTypes.length === 0 ||
      supportedRequestTypes.includes(opt.value),
  );
}

export function filterMenusByEnabledMenusFromSchoolConfiguration(
  node,
  defaultMenus,
  enabledMenus,
) {
  if (!enabledMenus.length) {
    return defaultMenus;
  }

  const roles = Perm.getRolesAssignedToNode(node, true);

  if (Perm.hasPerm('root') || roles.includes(nodeRoles.ROOT)) {
    return defaultMenus.filter(
      (menu) => enabledMenus.includes(menu) || menu.divider,
    );
  } else if (!roles || !roles.length) {
    return [];
  }

  return defaultMenus.filter((menu) => {
    if (!enabledMenus.includes(menu) && !menu.divider) {
      return false;
    }
    return roles.includes(menu);
  });
}

export function getFontByLanguage(font, language = defaultLanguage) {
  if (!Array.isArray(font) || !font.length) {
    return 'default';
  }
  const matchedFont = font.find((f) => get(f, 'language') === language);

  return matchedFont ? matchedFont.font : 'default';
}

export function getTextFromValue(value, options) {
  const matchedOption = options.find((option) => option.value === value);

  return matchedOption
    ? matchedOption.primaryText || matchedOption.label
    : 'N/A';
}

export function getMenuNavOptions(menus, filters = []) {
  if (!menus || !menus.length) {
    return [];
  }

  const options = [];
  menus.forEach((item) => {
    if (!item) return;
    if (!item.subMenu || item.subMenu.length === 0) {
      options.push({
        value: item.id,
        label: (
          <>
            {item.domainHintText ? <b>[{item.domainHintText}] </b> : null}
            {item.title} ({item.id})
          </>
        ),
      });
    } else {
      item.subMenu.forEach((child) => {
        if (child) {
          options.push({
            value: child.id,
            label: (
              <>
                {child.domainHintText ? <b>[{child.domainHintText}] </b> : null}
                {child.title} ({child.id})
              </>
            ),
          });
        }
      });
    }
  });
  if (!filters || !filters.length) {
    return options;
  }

  return options.filter((option) => filters.includes(option && option.value));
}

export function getRoleName(role) {
  if (!role) return '';

  const { code } = role;
  if (!code) return '';

  const arr = code.split(':');
  if (!arr || arr.length < 1) return '';

  return arr[arr.length - 1];
}

export function arrayEqual(array1, array2, callback) {
  if (array1 === array2) return true;
  if (array1 == null || array2 == null) return false;
  if (array1.length !== array2.length) return false;

  for (let i = 0; i < array2.length; ++i) {
    if (callback) {
      if (!callback(array1[i], array2[i])) return false;
    } else if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}

export const hoursStringify = (targetUnitNames) => (value) => {
  if (value < 24) {
    return `${value} ${value > 1 ? t('hours') : t('hour')}`;
  }

  let clonedValue = value;
  const units = {
    year: 24 * 365,
    month: 24 * 30,
    week: 24 * 7,
    day: 24,
    hour: 1,
  };

  const convertedStrings = [];
  Object.keys(units).forEach((name) => {
    if (!targetUnitNames.includes(name)) {
      return;
    }

    const unitValue =
      units[name] !== 1
        ? Math.floor(clonedValue / units[name])
        : parseFloat(clonedValue.toFixed(1));

    if (unitValue === 1) {
      convertedStrings.push(`${unitValue} ${t(name)}`);
    }
    if (unitValue >= 2) {
      convertedStrings.push(`${unitValue} ${t(`${name}s`)}`);
    }

    clonedValue %= units[name];
  });

  return convertedStrings.join(' ');
};

export const minuteStringify = (value) => `${value} ${t1('minutes')}`;

export const findItemInTree = (
  tree,
  childrenField = 'children',
  key = 'iid',
  value = null,
) => {
  const traverse = (obj) => {
    if (obj === undefined || obj === null) {
      return null;
    }

    if (obj[key].toString() === value.toString()) {
      return obj;
    }

    const children = obj[childrenField];
    if (children && typeof children === 'object') {
      for (let k in children) {
        if (children[k] && typeof children[k] === 'object') {
          const found = traverse(children[k]);

          if (found) {
            return found;
          }
        }
      }
    }

    return null;
  };

  return traverse(tree);
};

const REFERER_LOCAL_STORAGE_KEY = 'refererLink';

export const saveRefererLink = (link) =>
  localStorage.setItem(REFERER_LOCAL_STORAGE_KEY, link);
export const getRefererLink = () =>
  localStorage.getItem(REFERER_LOCAL_STORAGE_KEY);
export const clearRefererLink = () =>
  localStorage.removeItem(REFERER_LOCAL_STORAGE_KEY);

export const highlightContent = (
  content,
  textHighlight,
  highlightClassName = 'highlight',
) => {
  const highlightReg = /(.*?)/g;
  const highlightHtml = `<span class="${highlightClassName}">$1</span>`;

  const textReg = content.replace(highlightReg, '$1');
  return textReg.replace(new RegExp(`(${textHighlight})`, 'gi'), highlightHtml);
};

export const generateRandomStr = (length = 6) =>
  Math.random()
    .toString(36)
    .substr(2, length);

export function isGuide(item) {
  let isGuide = get(item, 'is_guide');

  return !!isGuide;
}

export function isFreeze(item) {
  let isFreeze = get(item, 'freeze');

  return !!isFreeze;
}

export function isFreezeMarking(item, userInfo) {
  let isFreeze = get(item, 'freeze');

  return isFreeze === freezeMode.FREEZE_MODE_COMPLETELY;
  // (isFreeze === freezeMode.FREEZE_MODE_LEARN && !isTeacher(userInfo))
}

export function getPlatformName(platform) {
  platform = Number(platform);

  return platform === PLATFORM_ANDROID
    ? 'Android'
    : platform === PLATFORM_IOS
    ? 'iOS'
    : platform === PLATFORM_WEB
    ? 'Web'
    : '';
}

export function shouldBeShowTrainingRegistration(userInfo) {
  return !!get(userInfo, 'have_to_register_for_training');
}

export const getFirstStringFromValueContainSplit = (split, value) => {
  if (!value) {
    return '';
  }

  if (!split) {
    return value;
  }

  const valueSplit = value.split(split);
  return valueSplit[0];
};

export const isServerBusy = () => !!window.SERVER_BUSY;

export const getSupportTime = () => window.SUPPORT_TIME;

export const getUserProjects = () => {
  const user = getUser();
  const userInfo = get(user, 'info', {});

  if (isEnableTemis()) {
    return [APPLICABLE_PROJECT.BDTX];
  }

  const ret = get(userInfo, 'projects_can_join', []);
  if (!Array.isArray(ret)) return [];

  return ret;
};

export const isProjectConfigBDTX = () => {
  const projectConfig = getProjectOfCurrentUser();

  return projectConfig === APPLICABLE_PROJECT.BDTX;
};

export const isBDTX = () => {
  const currentPathname = get(window, 'location.pathname');

  if (isEnableTemis() && !currentPathname.startsWith('/admin')) {
    return false;
  } else if (isEnableTemis()) {
    return true;
  }

  const projects = getUserProjects();
  const justHaveBdtxProject =
    projects.length === 1 && projects.includes(APPLICABLE_PROJECT.BDTX);

  const joinToBDTX = projects.length > 1 && isProjectConfigBDTX();

  return joinToBDTX || justHaveBdtxProject;
};

export const isHCMChuyenDoiSo = () => {
  return !!get(window, 'IS_HCM_CHUYEN_DOI_SO');
};

export const isETEP = () => !!window.isETEP;

export const getDefaultHomePage = (userInfo) => {
  const user = getUser();
  const userToCheck = userInfo || get(user, 'info', {});

  const homePageForSpecialUser = getDefaultHomePageForSpecialUser(userToCheck);
  if (homePageForSpecialUser) {
    return homePageForSpecialUser;
  }

  if (isEnableTemis()) {
    return '/temis';
  }

  if (isBo(userToCheck)) {
    return '/';
  }

  if (!isBDTX() && doesUserHaveMission(userToCheck)) {
    return '/mission';
  }

  // if (isSoOrPhong(userToCheck) || isAmTinh(userToCheck)) {
  //   return '/admin';
  // }
  //
  // if (Perm.hasPerm('staff')) {
  //   return '/teacher';
  // }

  return '/';
};

const getDefaultHomePageForSpecialUser = (userToCheck) => {
  switch (Number.parseInt(get(userToCheck, 'iid') || 0, 10)) {
    case 1543306: //teacher
      return '/dashboard/training-registration';
    case 1543307: //core-teacher
      return '/teacher';
    case 1543308: //teacher-educator
      return '/teacher';
    case 1543314: //teacher-education-institution
      return '/teacher';
    case 1543309: //moet
      return '/teacher';
    case 1543343: //doet
      return '/cbql/dashboard';
  }

  return undefined;
};

export const useCloudContest = () => {
  return !!window.useCloudContest;
};
