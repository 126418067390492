import get from 'lodash.get';
import getUser from 'common/auth';
import Store from 'store';

const assessmentPhaseConfigKey = 'TEMIS_ASSESSMENT_PHASE_CONFIGURATION_KEY';

export const saveAssessmentPhaseToLocalStorage = (assessmentPhase, userIid) => {
  let iid = userIid;
  if (!iid) {
    const userInfo = getUser();
    iid = get(userInfo, 'info.iid', '');
  }
  const currentData = getAssessmentPhaseOfAllUserFromLocalStorage() || {};
  const newData = {
    ...currentData,
    [iid]: assessmentPhase,
  };

  localStorage.setItem(assessmentPhaseConfigKey, JSON.stringify(newData));
};

export const getAssessmentPhaseOfAllUserFromLocalStorage = () => {
  const dataString = localStorage.getItem(assessmentPhaseConfigKey);
  if (dataString) {
    return JSON.parse(dataString);
  }

  return null;
};

export const getAssessmentPhaseOfCurrentUser = () => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const dataFromLocalStorage =
    getAssessmentPhaseOfAllUserFromLocalStorage() || {};

  return get(dataFromLocalStorage, userId);
};

export const clearAssessmentPhaseOfCurrentUser = () => {
  saveAssessmentPhaseToLocalStorage('');
};

export const onSaveAssessmentPhase = (assessmentPhase, callback) => {
  saveAssessmentPhaseToLocalStorage(assessmentPhase);

  if (typeof callback === 'function') {
    callback();
  }
};

export const isChangeAssessmentPhase = (assessmentPhase) => {
  const currentData = getAssessmentPhaseOfCurrentUser();
  return currentData !== assessmentPhase;
};

export const getAssessmentPhaseNameOfCurrentUser = () => {
  const schoolYears = get(Store.getState(), 'scholasticConfig.schoolYears', []);
  const selectedSchoolYearId = getAssessmentPhaseOfCurrentUser();
  const selectedSchoolYear = schoolYears.find(
    (schoolYear) => schoolYear.id == selectedSchoolYearId,
  );

  return get(selectedSchoolYear, 'name', '');
};
