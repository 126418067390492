import { t1 } from 'translate';
import { caphoc, targetUser } from 'components/common/elements';
import { organizations } from 'components/admin/organization/schema/elements';
import subTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, step, xpath, props) => {
  return {
    organizations: organizations({
      formid,
      multiple: false,
      label: t1('user_organization'),
      populateDefaultValue: true,
      isBdtx: true,
      subTypesDiscarded: [subTypes.TAPHUAN_SUBTYPE_LTTU],
    }),
    target_users: targetUser({
      defaultValue: '',
      classWrapper: 'col-md-6',
    }),
    caps: caphoc({
      defaultValue: '',
      classWrapper: 'col-md-6',
    }),
  };
};

const ui = () => {
  let fields = ['organizations', 'caps', 'target_users'];

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
