import { t1 } from 'translate';
import { caphoc, targetUser } from 'components/common/elements';
import { organizations } from 'components/admin/organization/schema/elements';
import subTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, step, xpath, props) => {
  return {
    mails: {
      type: 'text',
      floatingLabelText: t1('input_list_email_or_code_of_user'),
      classWrapper: 'col-md-12',
      multiLine: true,
      fullWidth: true,
    },
    organizations: organizations({
      formid,
      multiple: false,
      label: t1('user_organization'),
      populateDefaultValue: true,
      isBdtx: true,
      subTypesDiscarded: [subTypes.TAPHUAN_SUBTYPE_LTTU],
      classWrapper: 'col-md-6',
    }),
    target_users: targetUser({
      defaultValue: [],
      classWrapper: 'col-md-6',
    }),
    caps: caphoc({
      defaultValue: [],
      classWrapper: 'col-md-6',
    }),
    learn_types: {
      type: 'multiCheckbox',
      floatingLabelText: t1('learn_type_passed_or_failed'),
      fullWidth: true,
      inline: true,
      options: [
        { value: 'passed', label: t1('learn_type_passed') },
        { value: 'failed', label: t1('learn_type_failed') },
      ],
      classWrapper: 'col-md-6',
    },
  };
};

const ui = () => {
  let fields = [
    'mails',
    'organizations',
    'caps',
    'target_users',
    'learn_types',
  ];

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
