import '../stylesheet.scss';

import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import Request from 'common/network/http/Request';
import nodeActions from 'actions/node/creators';
import actions from 'actions/node/creators';
import { closeLoginDialog } from 'actions/auth/auth-dialog';
import { loginSuccess, setLoginCaptchaEnabled } from 'actions/auth';
import sagaActions from 'actions/saga-creators';
import {
  clearRefererLink,
  getDefaultHomePage,
  getRefererLink,
} from 'utils/Util';
import ForgotPasswordContainer from 'components/user/profile/forgot-password/Container';
import AuthCodeInput from '../two-factor-authentication/AuthCodeInput';
import errorCodes from 'common/errorCodes';
import { saveAssessmentPhaseToLocalStorage } from 'components/temis/components/scholastic-config/utils';

class LoginAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.doLoginAction = this.doLoginAction.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, userInfo, domainInfo } = this.props;
    if (domainInfo && userInfo && prevProps.domainInfo !== domainInfo) {
      const rootUrl = '/';
      const refererLink = getRefererLink();

      if (refererLink && refererLink !== rootUrl) {
        history.push(refererLink);
        clearRefererLink();
      } else {
        const redirectUrl = getDefaultHomePage(userInfo);
        history.push(redirectUrl);
      }
    }
  }

  doLoginAction(values, themeConfig, dispatch, history) {
    if (values && values.lname && values.pass) {
      const url = window.isETEP
        ? '/user/login-from-viettel-sso'
        : '/user/login';
      // const url = '/user/login';
      Request.post(url, values)
        .then((response) => {
          if (response.success && response.result) {
            dispatch(closeLoginDialog());

            const user = response.result;
            const assessmentPhase = lodashGet(user, 'assessment_phase');
            if (assessmentPhase) {
              saveAssessmentPhaseToLocalStorage(
                assessmentPhase,
                lodashGet(user, 'iid'),
              );
            }

            const onLoginSuccessful = () => {
              dispatch(loginSuccess(response.result));
              dispatch(setLoginCaptchaEnabled(false));

              dispatch(
                sagaActions.getInformationByDomain(window.location.hostname),
              );
            };

            let codeVerificationType;
            if (!!user.ga_enabled) {
              codeVerificationType = '2fa';
            } else if (!!user.enable_verify_token) {
              codeVerificationType = 'verify_token';
            }

            if (!codeVerificationType) {
              return onLoginSuccessful();
            }

            const dialogKey = 'AuthCodeInput';
            dispatch(
              actions.handleOpenDialog(
                {
                  contentDialog: (
                    <div>
                      <AuthCodeInput
                        codeVerificationType={codeVerificationType}
                        params={{
                          _sand_token: lodashGet(user, 'token'),
                          _sand_uiid: lodashGet(user, 'iid'),
                          _sand_uid: lodashGet(user, 'id'),
                          type: codeVerificationType,
                        }}
                        onVerificationSuccessful={(sessionOf2FA) => {
                          if (!sessionOf2FA) {
                            return;
                          }

                          onLoginSuccessful();
                          dispatch(
                            actions.handleOpenDialog(
                              { openDialog: false },
                              dialogKey,
                            ),
                          );
                        }}
                      />
                    </div>
                  ),
                  optionsProperties: {
                    handleClose: true,
                    title: t1('two_factor_authentication_code'),
                    modal: true,
                  },
                },
                dialogKey,
              ),
            );
          } else {
            let errMessage = response.message;
            if (errMessage === t1('form_invalid')) {
              errMessage = t1('please_check_login_form_data');
            }

            dispatch(nodeActions.snackbar('error', errMessage, 3));

            // there are some errors with captcha fields => captcha needed
            const captchaErrored = Boolean(lodashGet(response, 'err.captcha'));

            if (captchaErrored) {
              // enable captcha, or change it ID to re-render
              const captchaId = performance.now();
              dispatch(setLoginCaptchaEnabled(true, captchaId));
            }

            const bruteForceCheckerFailed =
              lodashGet(response, 'err_code') ==
              errorCodes.ERR_BRUTE_FORCE_CHECKER_FAILED;

            if (bruteForceCheckerFailed) {
              const contentDialog = (
                <ForgotPasswordContainer isCompact={true} />
              );
              const optionsProperties = {
                handleClose: true,

                title: t1('forgot_password_form'),
                modal: true,
              };
              dispatch(
                actions.handleOpenDialog({ contentDialog, optionsProperties }),
              );
            }
          }
        })
        .catch((response) => {
          dispatch(nodeActions.snackbar('error', response.message));
        });
    } else {
      dispatch(
        nodeActions.snackbar('warning', t1("the_login_fields_can't_be_empty")),
      );
    }
  }
}

export default LoginAction;
